import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { checkedChecklist, uncheckedChecklist } from '../../redux/modules/checklist';

import styles from "./ModuleChecklist.module.scss";
import { useRouter } from "next/router";

const mapStateToProps = (state) => {
  console.log("state", state)
  const {
    user: { firstName, lastInitial, id },
    checklist: { checklistItems }
  } = state;
  return {
    firstName,
    lastInitial,
    id,
    checklistItems
  };
};

const mapDispatchToProps = {
  checkedUser: checkedChecklist,
  uncheckedUser: uncheckedChecklist
};

function ModulEachChecklist({ _id, contentTypeId, headerText, summaryText, summaryRichText, key, index, openIndex, setOpenIndex, id, firstName, lastInitial, checklistItems, checkedUser, uncheckedUser }) {
  console.log("keysss", Object.entries(checklistItems), index)
  const router = useRouter();
  const [checkState, setCheckState] = useState(true)
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    setIsUpdated(true)

  }, [])
  const handleCheckboxClick = (e, index) => {
    console.log("bb", e, index)
    if (id) {
      setCheckState(false)
      setIsUpdated(false)
      checkedUser(index)
    }
    else {
      setCheckState(false)

      setIsUpdated(true)
    }

    
  }
  const handleCheckbox1Click = (e, index) => {
    if (id) {
      setCheckState(true)
      setIsUpdated(true)
      uncheckedUser(index)
    }
    else {
      setCheckState(true)
      setIsUpdated(true)
    }

    
  }

  return (
    <div className={styles._id}>
      <div >
        <div style={{ display: "flex" }}>

          {id &&
            Object.entries(checklistItems) && Object.entries(checklistItems).length > 0 &&
            Object.entries(checklistItems).map((ele, idx) => {
              console.log("eleeeeeee", ele, idx, ele[idx], index, ele[0] === index.toString(), ele[1], ele[1]?.checked)
              if (ele && ele[0] === index.toString() && ele[1]?.checked) {

                return (
              
                  <div key={idx}>{checkState ?
                    <div onClick={(e) => handleCheckboxClick(e, index)} id={`uncheckedSVG_${index}`} >
                      <svg width="24" height="24" version="1.1" xmlns="http://www.w3.org/2000/svg" desc="Created with imagetracer.js version 1.2.6"><path fill="rgb(0,0,0)" stroke="rgb(0,0,0)" stroke-width="1" opacity="0" d="M 0 0 L 24 0 L 24 24 L 0 24 L 0 0 Z M 5 5 L 5 19 L 19 19 L 19 5 L 5 5 Z " /><path fill="rgb(0,0,0)" stroke="rgb(0,0,0)" stroke-width="1" opacity="0" d="M 7 7 L 17 7 L 17 17 L 7 17 L 7 7 Z " /><path fill="rgb(20,19,16)" stroke="rgb(20,19,16)" stroke-width="1" opacity="0.5215686274509804" d="M 6 6 L 18 6 L 18 18 L 6 18 L 6 6 Z M 7 7 L 7 17 L 17 17 L 17 7 L 7 7 Z " /><path fill="rgb(19,18,17)" stroke="rgb(19,18,17)" stroke-width="1" opacity="1" d="M 5 5 L 19 5 L 19 19 L 5 19 L 5 5 Z M 6 6 L 6 18 L 18 18 L 18 6 L 6 6 Z " /></svg>
                    </div> :
                    <div onClick={(e) => handleCheckbox1Click(e, index)} id={`checkedSVG_${index}`}>
                      <svg width="24" height="24" version="1.1" xmlns="http://www.w3.org/2000/svg" desc="Created with imagetracer.js version 1.2.6"><path fill="rgb(28,24,24)" stroke="rgb(28,24,24)" stroke-width="1" opacity="0.12549019607843137" d="M 14.5 9 L 16 9.5 L 10.5 13 L 8 11.5 L 9.5 11 L 11.5 12 L 14.5 9 Z " /><path fill="rgb(28,24,24)" stroke="rgb(28,24,24)" stroke-width="1" opacity="0.12549019607843137" d="M 15.5 11 L 11.5 16 L 8 13.5 L 10.5 15 L 15.5 11 Z " /><path fill="rgb(0,0,0)" stroke="rgb(0,0,0)" stroke-width="1" opacity="0" d="M 0 0 L 24 0 L 24 24 L 0 24 L 0 0 Z M 5 5 L 5 19 L 19 19 L 19 5 L 5 5 Z " /><path fill="rgb(0,0,0)" stroke="rgb(0,0,0)" stroke-width="1" opacity="0" d="M 7 7 L 17 7 L 17 17 L 7 17 L 7 7 Z M 15 9 L 12 12 L 8 11 Q 7 15 11 16 L 16 12 Q 17 8 15 9 Z " /><path fill="rgb(26,25,24)" stroke="rgb(26,25,24)" stroke-width="1" opacity="0.6039215686274509" d="M 6 6 L 18 6 L 18 18 L 6 18 L 6 6 Z M 7 7 L 7 17 L 17 17 L 17 7 L 7 7 Z " /><path fill="rgb(26,25,24)" stroke="rgb(26,25,24)" stroke-width="1" opacity="1" d="M 5 5 L 19 5 L 19 19 L 5 19 L 5 5 Z M 6 6 L 6 18 L 18 18 L 18 6 L 6 6 Z " /><path fill="rgb(26,25,24)" stroke="rgb(26,25,24)" stroke-width="1" opacity="1" d="M 14.5 10 L 15 11.5 L 10.5 15 L 9 12.5 L 10.5 13 L 14.5 10 Z " /></svg>
                    </div>}
                  </div>)
              }


            })}
          <> {isUpdated ? (checkState ?
            <div onClick={(e) => handleCheckboxClick(e, index)} id={`uncheckedSVG_${index}`} >
              <svg width="24" height="24" version="1.1" xmlns="http://www.w3.org/2000/svg" desc="Created with imagetracer.js version 1.2.6"><path fill="rgb(0,0,0)" stroke="rgb(0,0,0)" stroke-width="1" opacity="0" d="M 0 0 L 24 0 L 24 24 L 0 24 L 0 0 Z M 5 5 L 5 19 L 19 19 L 19 5 L 5 5 Z " /><path fill="rgb(0,0,0)" stroke="rgb(0,0,0)" stroke-width="1" opacity="0" d="M 7 7 L 17 7 L 17 17 L 7 17 L 7 7 Z " /><path fill="rgb(20,19,16)" stroke="rgb(20,19,16)" stroke-width="1" opacity="0.5215686274509804" d="M 6 6 L 18 6 L 18 18 L 6 18 L 6 6 Z M 7 7 L 7 17 L 17 17 L 17 7 L 7 7 Z " /><path fill="rgb(19,18,17)" stroke="rgb(19,18,17)" stroke-width="1" opacity="1" d="M 5 5 L 19 5 L 19 19 L 5 19 L 5 5 Z M 6 6 L 6 18 L 18 18 L 18 6 L 6 6 Z " /></svg>
            </div> :
            <div onClick={(e) => handleCheckbox1Click(e, index)} id={`checkedSVG_${index}`}>
              <svg width="24" height="24" version="1.1" xmlns="http://www.w3.org/2000/svg" desc="Created with imagetracer.js version 1.2.6"><path fill="rgb(28,24,24)" stroke="rgb(28,24,24)" stroke-width="1" opacity="0.12549019607843137" d="M 14.5 9 L 16 9.5 L 10.5 13 L 8 11.5 L 9.5 11 L 11.5 12 L 14.5 9 Z " /><path fill="rgb(28,24,24)" stroke="rgb(28,24,24)" stroke-width="1" opacity="0.12549019607843137" d="M 15.5 11 L 11.5 16 L 8 13.5 L 10.5 15 L 15.5 11 Z " /><path fill="rgb(0,0,0)" stroke="rgb(0,0,0)" stroke-width="1" opacity="0" d="M 0 0 L 24 0 L 24 24 L 0 24 L 0 0 Z M 5 5 L 5 19 L 19 19 L 19 5 L 5 5 Z " /><path fill="rgb(0,0,0)" stroke="rgb(0,0,0)" stroke-width="1" opacity="0" d="M 7 7 L 17 7 L 17 17 L 7 17 L 7 7 Z M 15 9 L 12 12 L 8 11 Q 7 15 11 16 L 16 12 Q 17 8 15 9 Z " /><path fill="rgb(26,25,24)" stroke="rgb(26,25,24)" stroke-width="1" opacity="0.6039215686274509" d="M 6 6 L 18 6 L 18 18 L 6 18 L 6 6 Z M 7 7 L 7 17 L 17 17 L 17 7 L 7 7 Z " /><path fill="rgb(26,25,24)" stroke="rgb(26,25,24)" stroke-width="1" opacity="1" d="M 5 5 L 19 5 L 19 19 L 5 19 L 5 5 Z M 6 6 L 6 18 L 18 18 L 18 6 L 6 6 Z " /><path fill="rgb(26,25,24)" stroke="rgb(26,25,24)" stroke-width="1" opacity="1" d="M 14.5 10 L 15 11.5 L 10.5 15 L 9 12.5 L 10.5 13 L 14.5 10 Z " /></svg>
            </div>) : null}
          </>


          <h2>{headerText}</h2>
        </div>
        <p>{summaryText}</p>
      </div>
    </div>


  )




}
export default connect(mapStateToProps, mapDispatchToProps)(ModulEachChecklist);