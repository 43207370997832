import React, { useState } from "react";
import styles from "./ModuleChecklist.module.scss";
import ModuleEachChecklist from "./ModuleEachChecklist";

function ModuleChecklist( props ) {
    console.log("props->",props);
    //const [openIndex, setOpenIndex] = useState(null);
    const {checklistItems,internalTitle}=props
   
    return (
        <div className={styles.accordian}>
                <h2>{internalTitle}</h2>
                {checklistItems && checklistItems.length>0 ?
                 checklistItems.map((ele, index) => {
                    return (
                        <div key={index}>
                            <ModuleEachChecklist
                               index={index}
                               _id={ele._id}
                               contentTypeId={ele._contentTypeId}
                               headerText={ele.headerText}
                               summaryText={ele.summaryText}
                               key={ele._id}/>
                           
                        </div>
                    );
                }):null}
        </div>
    );
}

export default ModuleChecklist;
