
import {
  CHECKED_CHECKLIST,
  UNCHECKED_CHECKLIST
} from './checklist.types';


export const checkedChecklist = (index) => async (dispatch, getState) => {
  console.log("getstate", getState(), index)
    dispatch({
      type: CHECKED_CHECKLIST,
      payload: {
        index
      }
    })
};

export const uncheckedChecklist = (index) => async (dispatch, getState) => {
  dispatch({
    type: UNCHECKED_CHECKLIST,
    payload: {
      index
    }
  })
};

