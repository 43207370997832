import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
// import { reducer as form } from 'redux-form';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import thunkMiddleware from 'redux-thunk';
import user from './modules/user';
import courses from './modules/courses';
import webinars from './modules/webinars';
import checklist from './modules/checklist';
const composeEnhancers = typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const bindMiddleware = (middleware) => {
  return composeEnhancers(applyMiddleware(...middleware));
};

export const combinedReducer = combineReducers({
  user,
  courses,
  webinars,
  checklist
});

const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload // apply delta from hydration
    };
    return nextState;
  }
  return combinedReducer(state, action);
};

const initStore = () => {
  return createStore(reducer, bindMiddleware([thunkMiddleware]));
};

export default createWrapper(initStore);
