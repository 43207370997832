
import {
  CHECKED_CHECKLIST,
  UNCHECKED_CHECKLIST
} from './checklist.types';

export const initialState = {
  checklistItems: []
};

export default function reducer(state = initialState, { type, payload }) {

  console.log("payload", payload)
  switch (type) {
    case CHECKED_CHECKLIST: {
      const { index } = payload;
      return {
        ...state,
        checklistItems: {
          ...state.checklistItems,
          [index]: { checked: true, unchecked: false } 
        }
      };
    }

    case  UNCHECKED_CHECKLIST: {
      const { index } = payload;

      return {
        ...state,
        checklistItems: {
          ...state.checklistItems,
          [index]: { checked: false, unchecked: true }  
        }
    }
  }
    default:
      return state;
  }
}
